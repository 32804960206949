<template>
    <div id="header-place"></div>
    <header class="w-full flex-row">
        <router-link
            to="/"
            class="flex-row jc-c b-t b-b b-l b-r ai-c gap-md bg-pink" id="logo-header">
            <svg
                v-if="menu_home != 'true'"
                viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21 19.8325V11.8958C21.0001 11.3436 20.889 10.7972 20.6735 10.2901C20.458 9.78299 20.1428 9.32582 19.747 8.94661L12.374 1.87998C12.0027 1.52394 11.511 1.32556 11 1.32556C10.489 1.32556 9.99734 1.52394 9.626 1.87998L2.253 8.94661C1.85722 9.32582 1.54195 9.78299 1.3265 10.2901C1.11104 10.7972 0.999942 11.3436 1 11.8958V19.8325C1 20.3705 1.21071 20.8864 1.58579 21.2668C1.96086 21.6472 2.46957 21.8609 3 21.8609H19C19.5304 21.8609 20.0391 21.6472 20.4142 21.2668C20.7893 20.8864 21 20.3705 21 19.8325Z" stroke="#131313" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <p>{{ logoname_menu }}</p>
        </router-link>
        <div class="flex-row ai-c b-t b-b bg-white" id="tagline-header">
            <div id="slide-track">
                <p> CONCEPTION DE SITE WEB DANS LES LANDES !  •  SPÉCIALISÉ DANS LES BOUTIQUES AGROALIMENTAIRE & BOISSONS.  •  EXPERTISE EN ANALISE WEB ET CONSEIL EN STRATÉGIE NUMÉRIQUE  • </p>
                <p> CONCEPTION DE SITE WEB DANS LES LANDES !  •  SPÉCIALISÉ DANS LES BOUTIQUES AGROALIMENTAIRE & BOISSONS.  •  EXPERTISE EN ANALISE WEB ET CONSEIL EN STRATÉGIE NUMÉRIQUE  • </p>
            </div>
        </div>
        <div
            v-if="!mobileView"
            class="flex-row jc-fe ai-c gap-lg b-t b-b b-l b-r bg-yellow" id="links-menu">
            <router-link
                to="/function"
                class="flex-row"
                :id="[active == 'service' ? 'active' : '']" >
                <p>
                    <span v-if="active == 'service'">&gt;</span><span v-else>_</span>Services
                </p>
            </router-link>
            <router-link
                to="/realisations"
                class="flex-row"
                :id="[active == 'realisation' ? 'active' : '']" >
                <p>
                    <span v-if="active == 'realisation'">&gt;</span><span v-else>_</span>Réalisations
                </p>
            </router-link>
            <router-link
                to="/contact"
                class="flex-row"
                :id="[active == 'contact' ? 'active' : '']" >
                <p>
                    <span v-if="active == 'contact'">&gt;</span><span v-else>_</span>Contact
                </p>
            </router-link>
        </div>
        <div
            v-else-if="mobileView"
            class="flex-row jc-fe ai-c gap-lg b-t b-b b-l b-r bg-yellow txt-28" id="links-menu"
            @click="showNav = !showNav" >
            <fa v-if="!showNav" icon="bars" />
            <fa v-else-if="showNav" icon="xmark" />
        </div>
    </header>
    <div
        v-if="mobileView"
        class="w-full flex-row jc-fe ai-c bg-yellow b-b b-l b-r p-v-md p-h-md gap-lg"
        :class="[active,{'open':showNav}]"
        id="menuMobile" >
        <router-link
            to="/function"
            class="flex-row"
            :id="[active == 'service' ? 'active' : '']" >
            <p>
                <span v-if="active == 'service'">&gt;</span><span v-else>_</span>Services
            </p>
        </router-link>
        <router-link
            to="/realisations"
            class="flex-row"
            :id="[active == 'realisation' ? 'active' : '']" >
            <p>
                <span v-if="active == 'realisation'">&gt;</span><span v-else>_</span>Réalisations
            </p>
        </router-link>
        <router-link
            to="/contact"
            class="flex-row"
            :id="[active == 'contact' ? 'active' : '']" >
            <p>
                <span v-if="active == 'contact'">&gt;</span><span v-else>_</span>Contact
            </p>
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'Menu',
    props: {
        menu_home: Boolean,
        active: String,
    },
    data: () => {
        return {
            mobileView: false,
            showNav: false,
            logoname_menu: 'Bienvenue_',
        };
    },
    created() {
        this.handleView();
        window.addEventListener('resize', this.handleView);
        if(this.menu_home == 'true') {
            this.logoname_menu = 'Bienvenue_';
        } else {
            this.logoname_menu = 'Arnaud_'
        }
    },
    methods: {
        handleView() {
            this.mobileView = window.innerWidth <= 768;
        }
    },
}
</script>