<template>
    <div class="w-full flex-row bg-white tab-flex-col" id="titleSection">
        <div
            class="b-l b-r b-b tab-w-full"
            :class="titleContSize" >
            <h2>{{ h2Title }}</h2>
            <p class="txt-pink" id="subTitle">{{ subTitle }}</p>
        </div>
        <div
            class="b-r b-b tab-w-full tel-b-l"
            :class="txtContSize"
            id="txtTitleSection" >
            {{ txtTitle }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'TitleSection',
    props: {
        h2Title: String,
        subTitle: String,
        titleContSize: String,
        txtContSize: String,
        txtTitle: String,
    }
}
</script>