<template>
    <svg class="w-full b-b b-l b-r" viewBox="0 0 1278 190" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_303_650" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="1278" height="190">
            <rect width="1278" height="190" fill="#FCFBFA"/>
        </mask>
        <g mask="url(#mask0_303_650)">
            <path d="M119.12 48.04C116.901 48.04 114.981 47.6347 113.36 46.824C111.739 46.056 110.501 44.9253 109.648 43.432C108.837 41.9387 108.496 40.1467 108.624 38.056L109.456 26.28C109.627 24.0187 109.285 22.3973 108.432 21.416C107.621 20.392 105.829 19.88 103.056 19.88H96.016V16.104H103.056C105.744 16.104 107.515 15.6133 108.368 14.632C109.264 13.6507 109.627 12.008 109.456 9.704L108.624 -2.136C108.496 -4.26934 108.837 -6.06134 109.648 -7.512C110.501 -9.00534 111.739 -10.136 113.36 -10.904C114.981 -11.7147 116.901 -12.12 119.12 -12.12H122.384V-8.344H119.12C117.157 -8.344 115.579 -7.78934 114.384 -6.68C113.232 -5.61334 112.72 -4.09867 112.848 -2.136L113.68 9.704C113.893 12.2213 113.317 14.2693 111.952 15.848C110.587 17.384 108.709 18.152 106.32 18.152V17.704C108.667 17.704 110.523 18.4933 111.888 20.072C113.296 21.6507 113.893 23.72 113.68 26.28L112.848 38.056C112.72 40.0187 113.232 41.5333 114.384 42.6C115.579 43.7093 117.157 44.264 119.12 44.264H122.384V48.04H119.12Z" fill="#0079BF"/>
            <path d="M314.28 149.76C309.8 148.64 306.376 146.496 304.008 143.328C301.64 140.16 300.456 136.16 300.456 131.328V122.112C300.456 118.912 300.984 116.064 302.04 113.568C303.128 111.072 304.696 108.992 306.744 107.328C308.824 105.632 311.336 104.416 314.28 103.68V106.752C312.072 107.296 310.184 108.272 308.616 109.68C307.048 111.056 305.848 112.8 305.016 114.912C304.184 117.024 303.768 119.424 303.768 122.112V131.328C303.768 133.952 304.184 136.32 305.016 138.432C305.848 140.544 307.048 142.304 308.616 143.712C310.216 145.12 312.104 146.08 314.28 146.592V149.76ZM325.301 149.76V146.592C327.509 146.08 329.397 145.12 330.965 143.712C332.533 142.304 333.733 140.544 334.565 138.432C335.397 136.32 335.813 133.952 335.813 131.328V122.112C335.813 119.424 335.381 117.024 334.517 114.912C333.685 112.8 332.485 111.056 330.917 109.68C329.381 108.272 327.509 107.296 325.301 106.752V103.68C328.245 104.416 330.741 105.632 332.789 107.328C334.869 108.992 336.437 111.072 337.493 113.568C338.581 116.064 339.125 118.912 339.125 122.112V131.328C339.125 136.16 337.941 140.16 335.573 143.328C333.205 146.496 329.781 148.64 325.301 149.76Z" fill="#E63326"/>
            <path d="M432.4 25.8L428.704 40.728H423.616L429.712 25.8H432.4ZM477.963 40.68L481.659 25.752H486.747L480.651 40.68H477.963Z" fill="#F087B2"/>
            <path d="M935.477 68.32L941.333 61.12H901.349V58.288H943.637L944.789 56.896C945.429 56.16 945.941 55.552 946.325 55.072C946.741 54.592 947.013 54.288 947.141 54.16C947.013 54 946.725 53.696 946.277 53.248C945.861 52.768 945.317 52.128 944.645 51.328L943.589 49.984H901.349V47.152H941.285L935.477 40H939.221L950.693 54.112L939.173 68.32H935.477Z" fill="#F8AC00"/>
            <path d="M1185.02 178.04V174.264H1188.22C1190.22 174.264 1191.8 173.709 1192.95 172.6C1194.15 171.533 1194.66 170.019 1194.49 168.056L1193.72 156.28C1193.55 153.72 1194.15 151.651 1195.51 150.072C1196.88 148.493 1198.73 147.704 1201.08 147.704V148.152C1198.69 148.152 1196.81 147.384 1195.45 145.848C1194.13 144.269 1193.55 142.221 1193.72 139.704L1194.49 127.864C1194.66 125.901 1194.15 124.387 1192.95 123.32C1191.8 122.211 1190.22 121.656 1188.22 121.656H1185.02V117.88H1188.22C1190.48 117.88 1192.42 118.285 1194.04 119.096C1195.66 119.864 1196.88 120.995 1197.69 122.488C1198.54 123.939 1198.88 125.731 1198.71 127.864L1197.94 139.704C1197.77 142.008 1198.11 143.651 1198.97 144.632C1199.86 145.613 1201.66 146.104 1204.34 146.104H1211.38V149.88H1204.34C1201.61 149.88 1199.82 150.392 1198.97 151.416C1198.11 152.397 1197.77 154.019 1197.94 156.28L1198.71 168.056C1198.88 170.147 1198.54 171.939 1197.69 173.432C1196.88 174.925 1195.66 176.056 1194.04 176.824C1192.42 177.635 1190.48 178.04 1188.22 178.04H1185.02Z" fill="#F087B2"/>
            <path d="M644.472 117.616C641.4 117.616 638.52 117.04 635.832 115.888C633.187 114.736 630.84 113.157 628.792 111.152C626.744 109.104 625.144 106.736 623.992 104.048C622.84 101.36 622.264 98.48 622.264 95.408C622.264 92.336 622.84 89.4773 623.992 86.832C625.144 84.144 626.723 81.776 628.728 79.728C630.776 77.68 633.144 76.08 635.832 74.928C638.52 73.776 641.4 73.2 644.472 73.2C647.544 73.2 650.424 73.776 653.112 74.928C655.8 76.08 658.147 77.68 660.152 79.728C662.2 81.7333 663.8 84.08 664.952 86.768C666.104 89.456 666.68 92.336 666.68 95.408C666.68 98.48 666.104 101.36 664.952 104.048C663.8 106.736 662.2 109.104 660.152 111.152C658.147 113.157 655.8 114.736 653.112 115.888C650.424 117.04 647.544 117.616 644.472 117.616ZM644.408 107.504C648.888 107.504 652.323 105.221 654.712 100.656L652.472 99.44C651.533 101.104 650.381 102.363 649.016 103.216C647.693 104.027 646.157 104.432 644.408 104.432C642.616 104.432 641.037 104.027 639.672 103.216C638.349 102.363 637.24 101.104 636.344 99.44L634.168 100.656C636.557 105.221 639.971 107.504 644.408 107.504ZM636.024 93.68C636.877 93.68 637.56 93.4027 638.072 92.848C638.627 92.2933 638.904 91.6107 638.904 90.8C638.904 89.9893 638.627 89.3067 638.072 88.752C637.56 88.1973 636.877 87.92 636.024 87.92C635.213 87.92 634.531 88.1973 633.976 88.752C633.421 89.3067 633.144 89.9893 633.144 90.8C633.144 91.6107 633.421 92.2933 633.976 92.848C634.531 93.4027 635.213 93.68 636.024 93.68ZM652.92 93.68C653.731 93.68 654.413 93.424 654.968 92.912C655.523 92.3573 655.8 91.6533 655.8 90.8C655.8 89.9893 655.523 89.3067 654.968 88.752C654.413 88.1973 653.731 87.92 652.92 87.92C652.109 87.92 651.427 88.1973 650.872 88.752C650.317 89.3067 650.04 89.9893 650.04 90.8C650.04 91.6107 650.317 92.2933 650.872 92.848C651.427 93.4027 652.109 93.68 652.92 93.68Z" fill="#00A247"/>
        </g>
    </svg>
    <div class="w-full b-b b-l b-r txt-center p-v-md txt-18">
        ©arnaud laborde
    </div>
</template>

<script>
export default {
    name: 'Footer',
}
</script>

<style>

</style>